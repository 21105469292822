/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Funcionalidades, PerfilUsuario, PerfilHierarquia } from 'src/models/enums-model';
import { AmbienteModel, Ambientes } from '../../models/ambiente-model';
import { UsuarioModel } from '../../models/usuario-model';
import { UtilService } from './util.service';

export enum EListaUrls {
  consultaInstuicaoTodas = '/cadastros/todasInstituicoes',
  consultaInstuicaoPorSituacao = '/cadastros/instituicao/todas/{ativas}',
  consultaInstituicaoPorCnpj = '/cadastros/instituicao/{cnpj}/cnpj/{ativas}',
  consultaInstuicaoPorId = '/cadastros/instituicao/{id}',
  consultaInstituicaoPorNome = '/cadastros/instituicao/{nome}/nome/{ativas}',
  consultaInstituicaoPorStatus = '/cadastros/instituicao/todas/{status}',
  consultaInstituicaoPorId = '/cadastros/instituicao/{instituicaoId}',
  consultaOrigemPorInstituicao = '/cadastros/instituicaoOrigem',
  consultaInstituicaoCredora = '/cadastros/instituicao/credoras/{status}',
  consultaUsuarioInstituicao = '/cadastros/instituicao/{instituicaoId}/usuarios/{idUsuario}',
  consultaInstituicaoTodasComClientId = '/cadastros/instituicao/comClientid',
  consultarTipoInstituicao = 'api/cadastros/instituicao/{instituicaoId}/tipos',
  inclusaoInstituicao = '/cadastros/instituicao',
  desativacaoInstituicao = '/cadastros/instituicao/{instituicaoId}/desativar',
  ativacaoInstituicao = '/cadastros/instituicao/{instituicaoId}/ativar',
  insereOrigemInstituicao = '/cadastros/instituicao/{instituicaoId}/origens',
  insereContatoInstituicao = '/cadastros/instituicao/{instituicaoId}/contatos',
  insereUsuarioInstituicao = '/cadastros/instituicao/{instituicao}/usuarios',
  agruparInstituicao = '/cadastros/instituicao/agrupar',
  //domínio
  consultaInstituicaoTipo = '/dominio/instituicaoTipo',
  listaPerfil = '/dominio/perfil',
  consultaCnpjGovbr = '/dominio/dadosDeCnpj/{cnpj}',
  consultaCpfGovbr = '/dominio/dadosDeCpf/{cpf}',
  perfisPorTipoInstituicao = '/dominio/perfisPorTipoInstituicao/{tipoDeInstituicao}',
  // atualizações
  alteraInstituicao = '/cadastros/instituicao/{instituicaoId}',
  alteraContatoInstituicao = '/cadastros/instituicao/{instituicaoId}/contatos/{contatoId}',
  alteraOrigemInstituicao = '/cadastros/instituicao/{instituicaoId}/origens/{origemId}',
  alteraTipoInstituicao = '/cadastros/instituicao/{instituicaoId}/tipos/{acao}',
  alteraUsuarioInstituicao = '/cadastros/instituicao/{instituicaoId}/usuarios/{usuarioId}',
  // cadastro
  registroPorCpf = '/registro/{cpf}/cpf',
  registroPorCpfLogado = '/consultaCidadao/minhaSituacao',
  registroPorCnpj = '/registro/{cnpj}/cnpj',
  quantidadePorInstituicao = '/registros/{instituicao}/instituicao/quantidade',
  registroPorInstituicao = '/registros/{instituicao}/instituicao',
  registroPorTransacao = '/registro/{transacao}/transacao',
  registroPorTransacaoHistorico = '/registro/{transacao}/transacao/historico',
  auditoriaConsultaPorCpfSolicitante = '/logs/{cpf}/{dataIni}/{dataFim}/cpfsolicitante',
  auditoriaConsultaPorCpfCnpjConsultado = '/logs/{cpfCnpj}/{dataIni}/{dataFim}/{tipoCpfCnpj}',
  consultaCnpjSobMinhaResponsabilidade = '/consultaCnpjSobMinhaResponsabilidade/{cnpj}',
  // consultaCnpjSobMinhaResponsabilidadePorTransacao = '/consultaCidadao/{transacao}/transacaoHistorico',
  consultaCnpjSobMinhaResponsabilidadePorTransacao = '/consultaPJSeSocio/{transacao}/{cnpjAutorizado}/transacaoHistorico',
  // consultaCnpjSobMinhaResponsabilidade = '/consultaCidadao/{cnpj}/cnpj',
  consultaEntesPublicos = '/consultaEntesPublicos/{cnpj}/cnpj',
  consultaEntesPublicosPorTransacao = '/consultaEntesPublicos/{transacao}/transacaoHistorico',
  consultaContratante = '/consultaContratante/{cpfcnpj}/{tipo}',
  consultaContratantePorCpf = '/consultaContratante/{cpf}/cpf',
  consultaContratantePorCnpj = '/consultaContratante/{cnpj}/cnpj',
  consultaCnpjAssociadoCPFGovbr = '/consultaPJSeSocio/{cnpj}/cnpj',
  consultaCnpjAssociadoCPFGovbrPorTransacao = '/consultaPJSeSocio/{transacao}/transacaoHistorico',
  consultaCidadaoHistorico = '/consultaCidadao/minhaSituacaoHistorico',
  consultaCidadaoHistPorTransacao = '/consultaCidadao/{transacao}/registroProprioHistorico',
  relatorioPendenciasCredora = '/relatorios/emiteCsv/{id_instituicao}/{situacaoSolicitada}',
  //login
  auditoriaLogin = '/login/consultar/{cpf}/{dataInicial}/{dataFinal}',
  //inclusão
  inclusaoDeRegistro = '/registro/{cpfCnpj}',
  consultaOrigemNiReferencia = '/registros/{origem}/{ni}/{referencia}/{cpfCnpj}',

  // remessa
  uploadRemessa = '/uploadFile/{instituicaoId}/{usuarioId}',
  consultaRemessa = '/consultarRemessaPorOrigem/{origem}/{numeroRemessa}/{dataInicial}/{dataFinal}',
  consultaErrosRemessa = '/remessas/erros/{id}/{pagina}/{qtd}',
  downloadArqRetornoRemessa = '/download/{arquivoId}',
  reprocessaArquivoRemessa = '/reprocessar/{arquivoId}',
  downloadRemessaLogs = '/download/erros/{instituicaoId}/{numeroRemessa}/{usuario}/{dataInicial}/{dataFinal}',
  relatorioBatch = '/relatoriobatch/consultarPorInstituicao/{instituicaoId}',
  downloadArqrelatorioBatch = '/relatoriobatch/download/{arquivoId}',

  // cadinSida/Bacen
  consultaSituacaoHistorico = '/consultaSituacaoHistorico/{origem}', //O3337AD
  listaContatosInstituicao = '/listaContatosInstituicao/{origem}', //O3337AE
  consultaNiBacen = '/consultaNiBacen/{tipoConsulta}/{codigoInstituicao}/{cpf}/{cnpj}', //O33337AF

  //conecta gov
  consultaUrlTodos = '/auditoria/conectagov/url',
  consultaAcessosTodos = '/auditoria/conectagov/todos',
  consultaAcessosDiarios = '/auditoria/conectagov/todos/diario',
  consultaAcessosDiariosPorInstituicaoUrl = '/auditoria/conectagov/acessos',
  consultaAcessosUrlPorHoraDia = '/auditoria/conectagov/acessos/{urlId}/url',

  //Carga e extração
  realizarExportacaoRegistros = '/apirest/registros/{cpf_cnpj}/{ambienteDestino}',
  realizarExportacaoInstituicoes = '/apirest/instituicoes/{ambienteDestino}',

  //Dados do usuário
  dadosUsuario = '/dadosUsuario/{cpf}',
  fotoUsuario = '/foto',

  //auditoria
  consultaVolumetriaTransacoes = '/auditoria/volumetria/todos',
  consultaVolumetriaDiario = '/auditoria/volumetria',
}

export enum EListaUrlsExternas {
  orgaosDeGoverno = 'https://www.gov.br/pt-br/orgaos-do-governo',
  acessoAInformacao = 'https://www.gov.br/acessoainformacao/pt-br',
  legislacao = 'http://www4.planalto.gov.br/legislacao',
  acessibilidade = 'https://www.gov.br/governodigital/pt-br/acessibilidade-digital',
  precisoDeAjuda = 'https://www.gov.br/pgfn/pt-br/servicos/perguntas-frequentes/cadin',
  sobreOCadin = 'https://www.gov.br/pgfn/pt-br/servicos/orgaos-publicos-e-parceiros/cadin',
  privacidade = 'https://contas.acesso.gov.br/privacidade',
  faq = 'https://www.gov.br/governodigital/pt-br/conta-gov-br/perguntas-frequentes/',
}

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public usuario: UsuarioModel = new UsuarioModel();
  public ambiente: AmbienteModel;
  private versao = '1.7.20';

  public listaInstituicao = [];

  public FuncionalidadePerfil = [
    {
      nome: Funcionalidades.INICIO,
      perfis: [
        PerfilUsuario.MASTER,
        PerfilUsuario.CADASTRADOR_GERAL,
        PerfilUsuario.TRANSACAO,
        PerfilUsuario.CADASTRADOR_USUARIO,
        PerfilUsuario.CONSULTA,
      ],
    },
    {
      nome: Funcionalidades.PGFN,
      perfis: [
        PerfilUsuario.MASTER,
        PerfilUsuario.CADASTRADOR_GERAL,
        PerfilUsuario.TRANSACAO,
        PerfilUsuario.CADASTRADOR_USUARIO,
        PerfilUsuario.CONSULTA,
      ],
    },
    {
      nome: Funcionalidades.CON_INSTITUICAO,
      perfis: [PerfilUsuario.MASTER, PerfilUsuario.CADASTRADOR_GERAL, PerfilUsuario.CADASTRADOR_USUARIO],
    },
    { nome: Funcionalidades.INC_INSTITUICAO, perfis: [PerfilUsuario.MASTER, PerfilUsuario.CADASTRADOR_GERAL] },
    { nome: Funcionalidades.ALT_INSTITUICAO, perfis: [PerfilUsuario.MASTER, PerfilUsuario.CADASTRADOR_GERAL] },
    { nome: Funcionalidades.AGR_INSTITUICAO, perfis: [PerfilUsuario.MASTER, PerfilUsuario.CADASTRADOR_GERAL] },
    {
      nome: Funcionalidades.CON_USUARIO,
      perfis: [
        PerfilUsuario.MASTER,
        PerfilUsuario.CADASTRADOR_GERAL,
        PerfilUsuario.TRANSACAO,
        PerfilUsuario.CADASTRADOR_USUARIO,
        PerfilUsuario.CONSULTA,
      ],
    },
    {
      nome: Funcionalidades.INC_USUARIO,
      perfis: [PerfilUsuario.MASTER, PerfilUsuario.CADASTRADOR_GERAL, PerfilUsuario.CADASTRADOR_USUARIO],
    },
    {
      nome: Funcionalidades.ALT_USUARIO,
      perfis: [PerfilUsuario.MASTER, PerfilUsuario.CADASTRADOR_GERAL, PerfilUsuario.CADASTRADOR_USUARIO],
    },
    {
      nome: Funcionalidades.CON_CIDADAO,
      perfis: [PerfilUsuario.CIDADAO],
    },
    {
      nome: Funcionalidades.CON_CIDADAO_HISTORICO,
      perfis: [PerfilUsuario.CIDADAO],
    },
    {
      nome: Funcionalidades.CON_NI,
      perfis: [PerfilUsuario.MASTER, PerfilUsuario.CADASTRADOR_GERAL],
    },
    {
      nome: Funcionalidades.CON_CREDORA,
      perfis: [PerfilUsuario.MASTER, PerfilUsuario.CADASTRADOR_GERAL],
    },
    {
      nome: Funcionalidades.INC_NI,
      perfis: [PerfilUsuario.MASTER, PerfilUsuario.CADASTRADOR_GERAL],
    },
    {
      nome: Funcionalidades.UPLOAD_REMESSA,
      perfis: [PerfilUsuario.MASTER, PerfilUsuario.TRANSACAO],
    },
    {
      nome: Funcionalidades.CON_REMESSA,
      perfis: [PerfilUsuario.MASTER, PerfilUsuario.TRANSACAO],
    },
    {
      nome: Funcionalidades.CON_AUDITORIA,
      perfils: [PerfilUsuario.MASTER, PerfilUsuario.TRANSACAO],
    },
  ];

  constructor(private router: Router, public platform: Platform, private util: UtilService) {
    //inicializa o ambiente
    const amb = Ambientes.DES;
    this.ambiente = new AmbienteModel(amb);
    this.setaAmbientePorUrl(amb);

    // modo claro/escuro
    this.setTheme(this.getParametroLS('modoescuro', false));
  }

  setaAmbientePorUrl(amb: Ambientes) {
    const local = window.location.hostname;
    this.ambiente.setAmbientePorLocal(local);
  }

  retornaUrlAuth(): string {
    return this.ambiente.urlBaseAuth;
  }

  retornaUrlSaidaGov(): string {
    return this.ambiente.urlSaidaGov;
  }

  obterUrl(qual: EListaUrls | string, parametros?: any): string {
    return this.ambiente.urlBase.concat(this.obterUrlBase(qual, parametros));
  }

  obterUrlCadastro(qual: EListaUrls | string, parametros?: any): string {
    return this.ambiente.urlCadastro.concat(this.obterUrlBase(qual, parametros));
  }
  obterUrlCadastroREST(qual: EListaUrls | string, parametros?: any): string {
    return this.ambiente.urlCadastroREST.concat(this.obterUrlBase(qual, parametros));
  }
  obterUrlRemessa(qual: EListaUrls | string, parametros?: any): string {
    return this.ambiente.urlRemessa.concat(this.obterUrlBase(qual, parametros));
  }
  obterUrlCadinSida(qual: EListaUrls | string, parametros?: any): string {
    return this.ambiente.urlCadinSida.concat(this.obterUrlBase(qual, parametros));
  }
  obterUrlValidador(qual: EListaUrls | string, parametros?: any): string {
    return this.ambiente.urlValidador.concat(this.obterUrlBase(qual, parametros));
  }

  obterUrlExportador(qual: EListaUrls | string, parametros?: any): string {
    return this.ambiente.urlExportador.concat(this.obterUrlBase(qual, parametros));
  }

  obterUrlAuthSso(qual: EListaUrls | string, parametros?: any): string {
    return this.ambiente.urlBaseAuthSso.concat(this.obterUrlBase(qual, parametros));
  }

  obterUrlBase(qual: EListaUrls | string, parametros?: any): string {
    let url = qual as string;
    if (parametros) {
      for (const k of Object.keys(parametros)) {
        url = url.replace(`{${k}}`, parametros[k]);
      }
    }
    return url;
  }

  // setters
  setTheme(modoEscuro: any) {
    // seta tema claro/escuro
    this.consolelog('setTheme-escuro:' + modoEscuro);
    if (modoEscuro === true) {
      document.body.setAttribute('color-theme', 'dark');
      this.setParametroLS('modoescuro', true);
    } else {
      document.body.setAttribute('color-theme', '');
      this.setParametroLS('modoescuro', false);
    }
  }

  // getters
  getVersao() {
    return this.versao;
  }
  getAmbiente() {
    return this.ambiente;
  }
  getUsuario() {
    return this.usuario;
  }

  getPerfisFuncionalidade(nome: Funcionalidades) {
    // retorna os perfis da funcionalidade
    let r = [];
    for (const item of this.FuncionalidadePerfil) {
      if (nome === item.nome) {
        r = item.perfis;
        break;
      }
    }
    return r;
  }

  getTemPerfilFuncionalidade(nomeFuncionalidade: string | Funcionalidades): boolean {
    let retorno: boolean;
    const funcionalidades = this.usuario.getFuncionalidades();
    if (!funcionalidades.length || this.getUsuario().visaoCidadao) {
      retorno =
        this.util.removerAcentos(nomeFuncionalidade.toLocaleUpperCase()) ===
        this.util.removerAcentos(String(Funcionalidades.CON_CIDADAO).toLocaleUpperCase());
      //this.consolelog('[1getTemPerfilFuncionalidade]nomeFuncionalidade:' + nomeFuncionalidade + ' - retorno:' + retorno);
      return retorno;
    }
    retorno = funcionalidades?.find(
      (i) =>
        this.util.removerAcentos(i.funcionalidade.toLocaleUpperCase()) ===
        this.util.removerAcentos(nomeFuncionalidade.toLocaleUpperCase())
    );

    // this.consolelog('[2getTemPerfilFuncionalidade]nomeFuncionalidade:' + nomeFuncionalidade + ' - retorno:' + (retorno !== undefined));
    return retorno !== undefined;
  }

  getTemPerfilRestricao(nomeFuncionalidade: string | Funcionalidades): boolean {
    let retorno: boolean;
    const funcionalidades = this.usuario.getFuncionalidades();
    if (!funcionalidades.length) {
      retorno = true;
      return retorno;
    }
    const funcionalidade = funcionalidades?.find(
      (i) =>
        this.util.removerAcentos(i.funcionalidade.toLocaleUpperCase()) ===
        this.util.removerAcentos(nomeFuncionalidade.toLocaleUpperCase())
    );
    retorno = funcionalidade.restricao === 'Sim';
    return retorno;
  }

  getTemPerfilFuncionalidade0(nome: Funcionalidades) {
    let r = false;
    const perfilUsuario = this.getUsuario().getPerfilCadin();
    for (const item of this.FuncionalidadePerfil) {
      if (nome === item.nome) {
        for (const perfil of item.perfis) {
          if (perfil === perfilUsuario) {
            r = true;
            break;
          }
        }
      }
    }
    return r;
  }

  getPerfilHierarquia(perfilUsuario: string) {
    let r = 100;
    for (const i in PerfilUsuario) {
      if (PerfilUsuario[i] === perfilUsuario) {
        r = PerfilHierarquia[i];
        break;
      }
    }
    return r;
  }

  // platform
  isMobile() {
    // this.consolelog('[global]isMobile:' + this.platform.is('mobile'));
    return this.platform.is('mobile');
  }
  isIOS() {
    return this.platform.is('ios');
  }
  getLarguraTela() {
    return this.platform.width();
  }
  getAlturaTela() {
    return this.platform.height();
  }

  /**
   * Grava um parâmetro na área SessionStorage do browser
   *
   * @param chave Chave a ser gravada
   * @param parametro Conteúdo da chave a ser gravada
   */
  setParametroLS(chave: string, parametro: any) {
    sessionStorage.setItem(chave, JSON.stringify(parametro));
  }

  /**
   * Lê um parâmetro da área SessionStorage do browser
   *
   * @param chave Chave a ser lida
   * @param limpar Remove a chave se true (default = true)
   * @returns Conteudo da chave lida
   */
  getParametroLS(chave: string, limpar: boolean = true) {
    let r: any[] = [];
    const ls = sessionStorage.getItem(chave);
    if (ls != null && ls !== undefined) {
      r = JSON.parse(ls);

      if (limpar) {
        this.setParametroLS(chave, []);
      }
    }
    return r;
  }

  /**
   * Grava um parâmetro na área LocalStorage do browser
   *
   * @param chave Chave a ser gravada
   * @param parametro Conteúdo da chave a ser gravada
   */
  setParametroLocalStorage(chave: string, parametro: any) {
    localStorage.setItem(chave, JSON.stringify(parametro));
  }

  /**
   * Lê um parâmetro da área LocalStorage do browser
   *
   * @param chave Chave a ser lida
   * @param limpar Remove a chave se true
   * @returns Conteudo da chave lida
   */
  getParametroLocalStorage(chave, limpar?: boolean) {
    const item = localStorage.getItem(chave);
    if (!item) {
      return null;
    }
    if (limpar) {
      localStorage.removeItem(chave);
    }
    return JSON.parse(item);
  }

  // outras funções
  consolelog(s: string) {
    //escreve no console.log
    if (this.ambiente.ambiente !== Ambientes.PRD) {
      console.log(s);
    }
    // console.log(s);
  }
}
